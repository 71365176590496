import * as yup from "yup";
import { textValidation } from "./inputSchemas";

export const employerRegistrySchema = yup.object().shape({
   employerRegistration: textValidation,
   division: textValidation,
   group: textValidation,
   fraction: textValidation,
   class: textValidation,
   address: textValidation,
   city: textValidation,
   denomination: textValidation,
   delegation: textValidation,
   subDelegation: textValidation,
});
