import { Route } from "react-router-dom";

const base_url =
   window.location.hostname === "test.admin.lecosy.com.mx" ||
   window.location.hostname === "www.test.admin.lecosy.com.mx"
      ? "https://test.admin.lecosy.com.mx/pc"
      : process.env.NODE_ENV === "production"
      ? "https://admin.lecosy.com.mx/pc"
      : "http://localhost:3001";

export const usePCManagementRoutes = () => {
   return (
      <>
         <Route
            path="materias-juridicas"
            Component={() => {
               window.location.replace(`${base_url}/materias-juridicas`);
               return null;
            }}
         />
         <Route
            path="actividades-economicas"
            Component={() => {
               window.location.replace(`${base_url}/actividades-economicas`);
               return null;
            }}
         />
         <Route
            path="condicionantes"
            Component={() => {
               window.location.replace(`${base_url}/condicionantes`);
               return null;
            }}
         />
         <Route
            path="marco-juridico"
            Component={() => {
               window.location.replace(`${base_url}/marco-juridico`);
               return null;
            }}
         />
      </>
   );
};
