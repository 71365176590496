import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { HeadersProps } from "../../components/ListComponent";
import { getChargeByGovernUsers, getGoberningBodiesByCompany } from "../../lib/governanceClient";
import { ClientConfigContext } from "../../context/client/clientConfigContext";
import { getUserDataGB } from "../../lib/usersBEClient";
import { useFetchCorporateData } from "../../components/Clients/SubComponents/ServiceInfoForm/Governance/CorporateDataInformation.tsx";

export interface SeriesInterface {
   title: string;
   nominalValue: number;
}

const useGetMembersInfo = (hideFullInfo: boolean) => {
   const [members, setMembers] = useState([]);
   const [governanceBody, setGovernanceBody] = useState<any>(null);
   const [series, setSeries] = useState<SeriesInterface[]>([]);
   const { clientInfo } = useContext(ClientConfigContext);
   const [isLoading, setIsLoading] = useState(true);
   const { corporateData } = useFetchCorporateData(clientInfo?._id);

   const isCivil = useMemo(() => corporateData?.societyKind === "Sociedad civil", [corporateData]);
   const getGovernance = useCallback(async () => {
      if (!clientInfo?._id) return;
      if (!corporateData) return;
      
      const response = await getGoberningBodiesByCompany(clientInfo._id, isCivil);
      if (!response) return;

      const assemblyName =
         corporateData?.societyKind === "Sociedad mercantil" ? "Asamblea de accionistas" : "Asamblea de socios";
      const body = response.find((b) => b.title === assemblyName);
      if (!body) return;

      const users = body.users;
      const ids: any = Array.from(new Set(users.map(({ user }) => user)));
      const chargesIds = users.map((e) => e.charge);

      const [chargeData, userData] = await Promise.all([getChargeByGovernUsers(chargesIds), getUserDataGB(ids)]);

      const completeProfile = users.map((user) => ({
         ...chargeData.find((e) => e._id === user.charge),
         ...userData.find((e) => e._id === user.user),
         ...user,
      }));

      setMembers(completeProfile.filter((user) => user.chargeName === "Accionista"));
      setGovernanceBody(body);

      const sortByTitle = (arr) => [...arr].sort((a, b) => a.title.localeCompare(b.title));
      corporateData?.societyKind === "Sociedad mercantil" && setSeries(sortByTitle(body.series));
   }, [clientInfo, corporateData]);

   useEffect(() => {
      getGovernance();
   }, [getGovernance]);

   const formatedMembers = useMemo(() => {
      if (governanceBody && corporateData) {
         if (members.length === 0) {
            setIsLoading(false);
            return [];
         }

         let totalCompanyCapital = 0;
         if (isCivil) {
            totalCompanyCapital = members.reduce((total, member) => {
               return total + (member?.socialParts?.reduce((sum, part) => sum + (part.value || 0), 0) || 0);
            }, 0);
         } else {
            members.map((member) => {
               return (totalCompanyCapital =
                  totalCompanyCapital +
                  series.reduce((sum, serie) => {
                     const action = member.actions.find((action) => action.title === serie.title);
                     if (action) {
                        return sum + serie.nominalValue * action.sharesAmount;
                     }
                     return sum;
                  }, 0));
            });
         }

         return members.map((member) => {
            if (isCivil) {
               const totalCapital = member?.socialParts?.reduce((sum, part) => sum + (part.value || 0), 0) || 0;
               setIsLoading(false);
               return {
                  name: member.businessName ? member.businessName : `${member.firstName} ${member.lastName}`,
                  founder: member.founder,
                  rfc: member.rfc,
                  propertyPercentage: !totalCapital ? 0 : ((totalCapital * 100) / totalCompanyCapital).toFixed(1) ?? 0,
                  partnerType: member.partnerType || "--",
                  socialParts: member?.socialParts?.length,
                  socialCapital: totalCapital,
                  votes: member?.socialParts?.reduce((sum, part) => sum + part.votes, 0) || 0,
                  userId: member.user,
               };
            } else {
               const actions = series.map((serie) => {
                  const action = member.actions.find((action) => action.title === serie.title);
                  return {
                     [serie.title]: action ? action.sharesAmount : 0,
                  };
               });
               const mergedActions = !hideFullInfo && Object.assign({}, ...actions);

               const totalCapital = series.reduce((sum, serie) => {
                  const action = member.actions.find((action) => action.title === serie.title);
                  if (action) {
                     return sum + serie.nominalValue * action.sharesAmount;
                  }
                  return sum;
               }, 0);

               setIsLoading(false);
               return {
                  name: member.businessName ? member.businessName : `${member.firstName} ${member.lastName}`,
                  founder: member.founder,
                  rfc: member.rfc,
                  propertyPercentage: !totalCapital ? 0 : ((totalCapital * 100) / totalCompanyCapital).toFixed(1) ?? 0,
                  ...mergedActions,
                  totalActions: actions.reduce((sum, action) => sum + Object.values(action)[0], 0),
                  socialCapital: totalCapital,
                  votes: member.actions.reduce((sum, action) => sum + action.votes, 0) || 0,
                  userId: member.user,
               };
            }
         });
      } else {
         setIsLoading(false);
         return [];
      }
   }, [governanceBody, members, series, hideFullInfo, isCivil, corporateData]);

   const seriesheaders: HeadersProps[] = useMemo(() => {
      const optional: HeadersProps[] =
         (!hideFullInfo &&
            series.map((serie) => {
               return {
                  headerTitle: `Acciones ${serie.title}`,
                  headerPosition: "center",
                  bodyPosition: "center",
                  type: "number",
               };
            })) ||
         [];

      if (isCivil) {
         optional.push({
            headerTitle: "Clase de socio",
            headerPosition: "center",
            bodyPosition: "center",
            type: "text",
         });
      }

      return [
         {
            headerTitle: isCivil ? "Socio" : "Socio / accionista",
            headerPosition: "center",
            bodyPosition: "left",
            type: "text",
         },
         { headerTitle: "Fundadores", headerPosition: "center", bodyPosition: "center", type: "checkIcon" },
         { headerTitle: "RFC", headerPosition: "center", bodyPosition: "center", type: "text" },
         {
            headerTitle: "Porcentaje de propiedad",
            headerPosition: "center",
            bodyPosition: "center",
            type: "percentage",
         },
         ...optional,
         {
            headerTitle: isCivil ? "Partes sociales" : "Total acciones",
            headerPosition: "center",
            bodyPosition: "center",
            type: "number",
         },
         { headerTitle: "Capital social", headerPosition: "center", bodyPosition: "center", type: "currency" },
         { headerTitle: "Número de votos", headerPosition: "center", bodyPosition: "center", type: "number" },
      ];
   }, [series, hideFullInfo, clientInfo, corporateData, isCivil]);

   return { formatedMembers, members, seriesheaders, series, isLoading };
};

export default useGetMembersInfo;
