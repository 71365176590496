import { Box, CircularProgress, Tab, Tabs } from "@mui/material";
import React, { useState } from "react";
import { GetAllServices } from "../../../lib/usersBEClient";
import { ServiceInfoProvider } from "../../../context/serviceInfoContext";
import { TabPanel } from "../../TabPanel";
import { ServiceLicencesComponent } from "../../Clients/SubComponents/ServiceLicencesComponent";
import { ServiceStatusComponent } from "../ServiceStatusComponent";

const servicesOrder = [
   "Canal de denuncias",
   "Programa de cumplimiento",
   "Gobierno corporativo",
   "Beneficiario controlador",
];

const CompanyServiceTab = () => {
   const { data: services, isLoading } = GetAllServices();
   const [value, setValue] = useState(0);
   const handleChange = (event: React.SyntheticEvent, newValue: number) => setValue(newValue);

   if (isLoading)
      return (
         <Box sx={{ display: "flex", justifyContent: "center" }}>
            <CircularProgress />
         </Box>
      );

   return (
      <>
         <Box
            display="flex"
            sx={{ borderBottom: 1, borderColor: "divider", borderTopRightRadius: 5, borderTopLeftRadius: 5 }}
            bgcolor="white"
            boxShadow={2}
         >
            <Tabs value={value} onChange={handleChange}>
               {services
                  // .filter((s) => s.service !== "Gestión interna" && s.service !== "Programa de cumplimiento")
                  .filter((s) => s.service !== "Gestión interna")
                  .sort((a, b) => {
                     const aKey = a.service;
                     const bKey = b.service;
                     return servicesOrder.indexOf(aKey) - servicesOrder.indexOf(bKey);
                  })
                  .map((service, index) => (
                     <Tab key={index} sx={{ fontSize: 14, fontWeight: 600 }} label={service.service} />
                  ))}
            </Tabs>
         </Box>
         <ServiceInfoProvider>
            {services
               .filter((s) => s.service !== "Gestión interna")
               // .filter((s) => s.service !== "Gestión interna" && s.service !== "Programa de cumplimiento")
               .sort((a, b) => {
                  const aKey = a.service;
                  const bKey = b.service;
                  return servicesOrder.indexOf(aKey) - servicesOrder.indexOf(bKey);
               })
               .map((service, index) => (
                  <TabPanel key={index} value={value} index={index}>
                     <Box mb={2} display={"flex"} flexDirection={"column"} rowGap={2}>
                        <ServiceLicencesComponent modify={true} service={service} />
                     </Box>
                  </TabPanel>
               ))}
            <ServiceStatusComponent />
         </ServiceInfoProvider>
      </>
   );
};

export default CompanyServiceTab;
