import axios, { AxiosError } from "axios";

const base_url =
   window.location.hostname === "test.admin.lecosy.com.mx" ||
   window.location.hostname === "www.test.admin.lecosy.com.mx"
      ? "https://test.server.lecosy.com.mx/gc"
      : process.env.NODE_ENV === "production"
      ? "https://server.lecosy.com.mx/gc"
      : "http://localhost:8003";

const host = base_url;
const CHARGES = "/charges";
const AFFAIR = "/affair";
const COMPANY_DETAILS = "/companyDetails";
const GROUP = "/consultive-group";
const GOVERNING_BODY = "/governing-body";

export const handleInvalidRequest = (error) => {
   const details: AxiosError = error as AxiosError;
   if (details.response && details.response.data === "expired") {
      console.log("request session expired");
      return null;
   }
   if (details.response && details.response.data === "no user") {
      console.log("request session expired");
      return null;
   }
};

export const getGoberningBodiesByCompany = async (companyId: string, isCivil?: boolean) => {
   try {
      axios.defaults.withCredentials = true;
      const data = await axios.put(`${host}${GOVERNING_BODY}/all/company/${companyId}`, {
         isCivil: !!isCivil && isCivil,
      });
      return data.data;
   } catch (error) {
      console.log(error);
      handleInvalidRequest(error);
   }
};

export const getGoberningBodiesByGroup = async (groupId: string) => {
   try {
      axios.defaults.withCredentials = true;
      const data = await axios.get(`${host}${GOVERNING_BODY}/all/group/${groupId}`);
      return data.data;
   } catch (error) {
      console.log(error);
      handleInvalidRequest(error);
   }
};

export const getMemberChargesByCompany = async (arrayIds: string[], companyId: string) => {
   try {
      axios.defaults.withCredentials = true;
      const data = await axios.put(`${host}${CHARGES}/members/${companyId}`, arrayIds);
      return data.data;
   } catch (error) {
      console.log(error);
      handleInvalidRequest(error);
   }
};

export const getMemberChargesByManyCompanies = async (usersArray: string[], companiesArray: string[]) => {
   try {
      axios.defaults.withCredentials = true;
      const data = await axios.put(`${host}${CHARGES}/members/companies`, { usersArray, companiesArray });
      return data.data;
   } catch (error) {
      console.log(error);
      handleInvalidRequest(error);
   }
};

export const getMemberChargesByConsultiveGroup = async (groupId: string, usersArray: string[]) => {
   try {
      axios.defaults.withCredentials = true;
      const data = await axios.put(`${host}${CHARGES}/members/group/${groupId}`, usersArray);
      return data.data;
   } catch (error) {
      console.log(error);
      handleInvalidRequest(error);
   }
};

export const getChargeByGovernUsers = async (arrayIds: any) => {
   try {
      axios.defaults.withCredentials = true;
      const data = await axios.post(`${host}${CHARGES}/members`, arrayIds);
      return data.data;
   } catch (error) {
      console.log(error);
      handleInvalidRequest(error);
   }
};

export const getAffairsByCompany = async (companyId: string) => {
   try {
      axios.defaults.withCredentials = true;
      const response = await axios.get(`${host}${AFFAIR}/company/${companyId}`);
      return response;
   } catch (error) {
      handleInvalidRequest(error);
   }
};

export const createCompanyDetails = async (companyId: string, data: any) => {
   try {
      axios.defaults.withCredentials = true;
      const body = {
         office: data.office,
         businessName: data.businessName,
         primaryColor: data.primaryColor,
         secondaryColor: data.secondaryColor,
      };
      const response = await axios.post(`${host}${COMPANY_DETAILS}/${companyId}`, body);
      return response;
   } catch (error) {
      handleInvalidRequest(error);
   }
};
export const updateCompanyDetails = async (companyId: string, data: any) => {
   try {
      axios.defaults.withCredentials = true;
      const body = {
         businessName: data.businessName,
         primaryColor: data.primaryColor,
         secondaryColor: data.secondaryColor,
      };
      const response = await axios.put(`${host}${COMPANY_DETAILS}/${companyId}`, body);
      return response;
   } catch (error) {
      handleInvalidRequest(error);
   }
};

export const getConsultiveGroupById = async (groupId: string) => {
   try {
      axios.defaults.withCredentials = true;
      const data = await axios.get(`${host}${GROUP}/${groupId}`);
      return data.data;
   } catch (error) {
      console.log(error);
      handleInvalidRequest(error);
   }
};
