import { Box, Button, Grid, Stack, Typography } from "@mui/material";
import { Form, Formik } from "formik";
import { InputTextField } from "../../../../../Inputs/InputTextField";
import { InputFile } from "../../../../../Inputs/InputFile";
import { useState } from "react";
import { employerRegistrySchema } from "../../../../../../lib/Validations/repseSchemas";

interface IEmployerRegistryModal {
   setOpen: Function;
}
export const EmployerRegistryModal = (props: IEmployerRegistryModal) => {
   const [file, setFile] = useState(null);
   const handleSubmit = (values) => {
      console.log({ values });
   };
   return (
      <Formik
         initialValues={{
            employerRegistration: "",
            division: "",
            group: "",
            fraction: "",
            class: "",
            address: "",
            city: "",
            delegation: "",
            subDelegation: "",
         }}
         onSubmit={handleSubmit}
         validationSchema={employerRegistrySchema}
      >
         <>
            <Box p={2} maxHeight={450} sx={{ overflowY: "scroll" }}>
               <Form>
                  <Grid container spacing={3}>
                     <Grid item xs={12} spacing={2}>
                        <InputTextField
                           disabled
                           size={"small"}
                           // label="# Registro"
                           id="index"
                           name="index"
                           type="text"
                           sx={{ bgcolor: "#EFEFEF" }}
                           value={"001"}
                        />
                        <InputFile file={file?.file} setFile={setFile} keyName="identificación patronal" />
                     </Grid>
                     <Grid item xs={7}>
                        <InputTextField
                           id="employerRegistration"
                           name={"employerRegistration"}
                           type="text"
                           size={"small"}
                           label="Número de registro patronal"
                           fullWidth
                        />
                     </Grid>
                     <Grid item xs={12} mx={5}>
                        <Box border={"dashed"} borderColor={"#CECECE"} px={2} py={3}>
                           <Grid container spacing={5}>
                              <Grid item xs={3}>
                                 <InputTextField
                                    id="division"
                                    name="division"
                                    type="text"
                                    size={"small"}
                                    label="División"
                                 />
                              </Grid>
                              <Grid item xs={3}>
                                 <InputTextField id="group" name={"group"} type="text" size={"small"} label="Grupo" />
                              </Grid>
                              <Grid item xs={3}>
                                 <InputTextField
                                    id="fraction"
                                    name={"fraction"}
                                    type="text"
                                    size={"small"}
                                    label="Fracción"
                                 />
                              </Grid>
                              <Grid item xs={3}>
                                 <InputTextField id="class" name={"class"} type="text" size={"small"} label="Clase" />
                              </Grid>
                           </Grid>
                        </Box>
                     </Grid>
                     <Grid item xs={8}>
                        <InputTextField
                           id="address"
                           name="address"
                           size={"small"}
                           type="text"
                           label="Domicilio"
                           fullWidth
                        />
                     </Grid>
                     <Grid item xs={4}>
                        <InputTextField
                           id="city"
                           name="city"
                           size={"small"}
                           type="text"
                           label="Lugar y fecha de expedición"
                           fullWidth
                        />
                     </Grid>
                     <Grid item xs={12}>
                        <InputTextField
                           id="denomination"
                           name="denomination"
                           size={"small"}
                           type="text"
                           fullWidth
                           label="Nombre, denominación o razón social del patrón o sujeto obligado"
                        />
                     </Grid>
                     <Grid item xs={6}>
                        <InputTextField
                           id="delegation"
                           name="delegation"
                           size={"small"}
                           type="text"
                           fullWidth
                           label="Delegación"
                        />
                     </Grid>
                     <Grid item xs={6}>
                        <InputTextField
                           id="subDelegation"
                           name="subDelegation"
                           size={"small"}
                           type="text"
                           fullWidth
                           label="Subdelegación"
                        />
                     </Grid>
                  </Grid>
               </Form>
            </Box>
            <Box width={"100%"} bgcolor={"#F3F3F3"} display={"flex"} borderRadius={2} px={3} py={2}>
               <Stack direction={"row"} spacing={2} display={"flex"} width={"100%"} justifyContent={"flex-end"}>
                  <Button onClick={() => props.setOpen(false)}>
                     <Typography sx={{ opacity: 0.5 }}>Cancelar</Typography>
                  </Button>
                  <Button type="submit">
                     <Typography>Guardar</Typography>
                  </Button>
               </Stack>
            </Box>
         </>
      </Formik>
   );
};
