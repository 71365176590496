import { Box, Stack, Switch, Typography } from "@mui/material";
import { ConfirmationModal } from "../ConfirmationModal";
import { useCallback, useContext, useEffect, useState } from "react";
import { SnackBarContext } from "../../context/snackBarContext";
import {
   createBeneficiaryApplicationConfig,
   getManyCompaniesById,
   useUpdateGroupServiceDisable,
} from "../../lib/usersBEClient";
import { ClientConfigContext } from "../../context/client/clientConfigContext";
import { BeneficiaryRequiredDocs } from "../../const/globalConst";
import LoginModal from "../ClientInformation/Modal/loginModal";

interface ModuleCheckProps {
   service: { _id: string; name: string };
}

const GroupModuleCheck = (props: ModuleCheckProps) => {
   const { service } = props;
   const { showSnackBar } = useContext(SnackBarContext);
   const { group, refreshGroupInfo } = useContext(ClientConfigContext);

   const { mutate: serviceStatus } = useUpdateGroupServiceDisable();

   const [checked, setChecked] = useState<boolean>(null);
   const [openDisableServiceModal, setOpenDisableServiceModal] = useState(false);
   const [openLoginModal, setOpenLoginModal] = useState(false);

   const verifyIfCompaniesService = useCallback(async () => {
      const companyIds = group.companies.map((c) => c._id);
      const companies = await getManyCompaniesById(companyIds);

      const allServicesEnabled = companies.every((c) =>
         c.company_details.servicesDetails.some((s) => s.serviceId === service._id && s.disable === false)
      );

      if (allServicesEnabled) setChecked(true);
      else setChecked(false);
   }, [group.companies, service._id, group]);

   useEffect(() => {
      verifyIfCompaniesService();
   }, [verifyIfCompaniesService]);

   const serviceUpdate = async () => {
      serviceStatus(
         {
            service_id: props.service._id,
            group: group._id,
            disable: checked,
         },
         {
            onSuccess: async () => {
               showSnackBar(`Servicio ${!checked ? "Habilitado" : "Inhabilitado"} correctamente`, false);
               if (!checked && service.name === "Beneficiario controlador") {
                  const promises = group.companies.map(async (company) => {
                     const data = {
                        company: company._id,
                        files: BeneficiaryRequiredDocs.map((file) => ({
                           fileName: file,
                           fileType: "PDF",
                           maxFileSize: 10 * (1024 * 1024), // 10 MB
                           periodType: "monthly",
                           periodFrequency: 3,
                        })),
                     };
                     return createBeneficiaryApplicationConfig(data);
                  });

                  await Promise.all(promises);
               }
               setChecked(!checked);
               refreshGroupInfo();
            },
            onError: () => {
               showSnackBar(`Error al ${!checked ? "Habilitar" : "Inhabilitar"}`, true);
               refreshGroupInfo();
            },
         }
      );
   };

   return (
      <Box>
         <Box
            sx={{
               alignItems: "center",
               display: "flex",
               justifyContent: "space-between",
            }}
         >
            <Typography fontWeight={600} variant="subtitle1">
               {service.name}
            </Typography>
            <Stack direction={"row"} columnGap={2}>
               <Switch
                  disabled={checked === null}
                  checked={checked}
                  onChange={() => {
                     setOpenDisableServiceModal(true);
                  }}
                  sx={{ display: "flex" }}
               />
            </Stack>
         </Box>
         <ConfirmationModal
            open={openDisableServiceModal}
            setOpen={setOpenDisableServiceModal}
            title={!checked ? `Habilitar servicio` : `Inhabilitar servicio`}
            body={`¿Está seguro de ${!checked ? "habilitar" : "inhabilitar"} este servicio para todo el grupo?`}
            onConfirm={() => {}}
            setOpenLogin={setOpenLoginModal}
         />
         <LoginModal open={openLoginModal} setOpen={setOpenLoginModal} onConfirm={serviceUpdate} />
      </Box>
   );
};

export default GroupModuleCheck;
