import { Box } from "@mui/material";
import { HeadCell, TableComponent } from "../../../../../TableComponent";
import EditIcon from "@mui/icons-material/Edit";

const EmployerRegistryTable = () => {
   const headers: HeadCell[] = [
      { headerName: "#", type: "number", field: "number" },
      { headerName: "Registro patronal del IMSS", type: "string", field: "employerRegistry", align: "center" },
      { headerName: "Delegación", type: "string", field: "delegation", align: "center" },
      { headerName: "Subdelegación", type: "string", field: "subdelegation", align: "center" },
      { headerName: "Fecha de expedición", type: "string", field: "expeditionDate", align: "center" },
      {
         headerName: "Modificar",
         type: "button",
         align: "center",
         field: "modify",
         icon: <EditIcon fontSize="small" />,
         onClick: (e, i) => {
            console.log({ e });
         },
      },
   ];
   return (
      <TableComponent
         disableBorders
         defaultColumnToOrder="number"
         defaultOrder="desc"
         defaultRowsPerPage={5}
         emptyDataText="No data"
         loader={false}
         onClick={() => {}}
         rowsPerPageOptions={[5, 10, 15]}
         headers={headers}
         rows={[
            {
               number: 0,
               employerRegistry: "123456789",
               delegation: "Delegación 1",
               subdelegation: "Subdelegación 1",
               expeditionDate: "01/01/2022",
               modify: <EditIcon />,
            },
         ]}
      />
   );
};

export default EmployerRegistryTable;
