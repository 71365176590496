import { Box, Typography } from "@mui/material";
import { formatCurrency } from "../../../../../../const/globalConst";
import ListComponent from "../../../../../ListComponent";
import useGetMembersInfo from "../../../../../../hooks/GC/useGetMembersInfo";
import { useContext, useMemo } from "react";
import { useFetchCorporateData } from "../CorporateDataInformation.tsx";
import { ClientConfigContext } from "../../../../../../context/client/clientConfigContext";

interface MembersListProps {
   setSelectedUser?: Function;
   setOpenModal?: Function;
   hideFullInfo?: boolean;
}

const MembersList = (props: MembersListProps) => {
   const { setSelectedUser, setOpenModal, hideFullInfo = false } = props;
   const { formatedMembers, members, seriesheaders, series } = useGetMembersInfo(hideFullInfo);
   const { clientInfo } = useContext(ClientConfigContext);
   const { corporateData } = useFetchCorporateData(clientInfo?._id);
   const isCivil = useMemo(() => corporateData?.societyKind === "Sociedad civil", [corporateData?.societyKind]);

   return (
      <Box sx={{ pt: 2 }}>
         <ListComponent
            title={isCivil ? "Capital social" : "Cuadro accionario"}
            titleProps={{
               py: 1.5,
               width: "100%",
               bgcolor: "#162c44",
               color: "white",
               borderTopLeftRadius: 10,
               borderTopRightRadius: 10,
            }}
            headers={seriesheaders}
            headerProps={{
               bgcolor: "#E2E6EA",
               px: 3,
               py: 1,
               borderRadius: 1,
               color: "#162c44",
            }}
            headerTextProps={{ textAlign: "center", fontSize: "14px", fontWeight: 500 }}
            rowProps={function (row, i) {
               return {
                  bgcolor: "white",
                  mb: 0.5,
                  border: 1,
                  borderColor: "#D9D9D9",
                  px: 2,
               };
            }}
            rowsColumnProps={() => {
               return {
                  alignContent: "center",
                  py: 0.5,
               };
            }}
            rows={(formatedMembers?.length > 0 && formatedMembers) || []}
            onClick={(_e, _row, index) => {
               setSelectedUser && setSelectedUser(members[index]);
               setOpenModal && setOpenModal(true);
            }}
            footer={
               formatedMembers?.length > 0 && [
                  <Typography key="total">Totales</Typography>,
                  <Typography fontWeight={600} key="founders">
                     {formatedMembers.reduce((sum, member) => sum + (member.founder ? 1 : 0), 0)}
                  </Typography>,
                  <Typography key="empty" />,
                  <Typography fontWeight={600} key="property">
                     100%
                  </Typography>,
                  ...(isCivil
                     ? [
                          <Typography key="empty2" />,
                          <Typography key="socialParts">
                             {formatCurrency(formatedMembers.reduce((sum, member) => sum + member.socialParts, 0))}
                          </Typography>,
                       ]
                     : [
                          !hideFullInfo &&
                             series.map((serie) => (
                                <Typography key={serie.title}>
                                   {formatCurrency(
                                      formatedMembers.reduce((sum, member) => sum + member[serie.title], 0)
                                   )}
                                </Typography>
                             )),
                          <Typography key="actionsValue">
                             {formatCurrency(formatedMembers.reduce((sum, member) => sum + member.totalActions, 0))}
                          </Typography>,
                       ]
                  )
                     .flat()
                     .filter(Boolean),
                  <Typography key="socialCapital">{`$${formatCurrency(
                     formatedMembers.reduce((sum, member) => sum + member.socialCapital, 0)
                  )}`}</Typography>,
                  <Typography key="votesQuantity">
                     {formatCurrency(formatedMembers.reduce((sum, member) => sum + member.votes, 0))}
                  </Typography>,
               ]
            }
         />
      </Box>
   );
};

export default MembersList;
