import { Box, Stack } from "@mui/material";
import { ComplaintsFormComponent } from "./ServiceInfoForm/ComplaintsForm";
import { useState, useContext } from "react";
import { ClientConfigContext } from "../../../context/client/clientConfigContext";
import CorporateDataInformation from "./ServiceInfoForm/Governance/CorporateDataInformation.tsx";
import { GoverningBodiesComponent } from "./ServiceInfoForm/Governance/GoverningBodiesComponent";
import CompanyCoordination from "./ServiceInfoForm/Governance/subcomponents/CompanyCoordination";
import ModuleCheck from "../ModuleCheck";
import { ClientCreationContext } from "../../../context/client/clientCreationContext";
import { Companies } from "../../../types/BaseTypes";
import RepseModuleInfo from "./ServiceInfoForm/RepseSiroc/RepseModuleInfo";
import EmployerRegistrySection from "./ServiceInfoForm/RepseSiroc/EmployerRegistry/EmployerRegistrySection";
import EmployerUserSection from "./ServiceInfoForm/RepseSiroc/EmployerRegistry/EmployerUsersSection";
interface ServiceLicencesComponentProps {
   service: any;
   modify: boolean;
}

function getService(services: any[], serviceId: string) {
   for (const service of services) {
      if (service?.serviceId === serviceId || service?.serviceId?._id === serviceId) {
         return service;
      }
   }
}

export const ServiceLicencesComponent = (props: ServiceLicencesComponentProps) => {
   const { clientInfo: clientInfoContext } = useContext(ClientConfigContext);
   const { selectedClient } = useContext(ClientCreationContext);
   const clientInfo = clientInfoContext ?? (selectedClient?.data as Companies);
   const serviceDetails = getService(clientInfo?.company_details?.servicesDetails, props.service._id);
   const [checked, setChecked] = useState<boolean>(serviceDetails !== undefined && !serviceDetails?.disable);

   const getserviceInfo = (service: string) => {
      switch (service) {
         case "Canal de denuncias":
            return <ComplaintsFormComponent modify={props.modify ?? false} checked={checked} service={props.service} />;
         case "Programa de cumplimiento":
            return <RepseModuleInfo />;
         case "Gobierno corporativo":
            return props.modify && <CorporateDataInformation checked={checked} service={props.service} />;
         case "Beneficiario controlador":
            return props.modify && <CorporateDataInformation checked={checked} service={props.service} />;
         default:
            break;
      }
   };

   return (
      <>
         <Box
            sx={{
               px: 3,
               py: 2,
               borderBottomRightRadius: 5,
               borderBottomLeftRadius: 5,
               alignItems: "center",
               boxShadow: 2,
               backgroundColor: "white",
            }}
         >
            <ModuleCheck
               service={{ _id: props.service._id, name: props.service.service }}
               checked={checked}
               setChecked={setChecked}
            />
            {getserviceInfo(props.service.service)}
         </Box>

         {props.service.service === "Programa de cumplimiento" && (
            <Stack direction={"column"} spacing={2}>
               <EmployerRegistrySection />
               <EmployerUserSection />
            </Stack>
         )}
         {props.service.service === "Gobierno corporativo" && props.modify && checked && (
            <>
               <Box
                  sx={{
                     px: 3,
                     py: 2,
                     borderRadius: 1,
                     alignItems: "center",
                     boxShadow: 2,
                     backgroundColor: "white",
                  }}
               >
                  <Box sx={{ opacity: checked ? 1 : 0.5, pointerEvents: checked ? "auto" : "none" }}>
                     <GoverningBodiesComponent company={clientInfo} />
                  </Box>
               </Box>
               <Box
                  sx={{
                     px: 3,
                     py: 2,
                     borderRadius: 1,
                     alignItems: "center",
                     boxShadow: 2,
                     backgroundColor: "white",
                  }}
               >
                  <Box sx={{ opacity: checked ? 1 : 0.5, pointerEvents: checked ? "auto" : "none" }}>
                     <CompanyCoordination />
                  </Box>
               </Box>
            </>
         )}

         {props.service.service === "Beneficiario controlador" && props.modify && checked && (
            <Box
               sx={{
                  px: 3,
                  py: 2,
                  borderRadius: 1,
                  alignItems: "center",
                  boxShadow: 2,
                  backgroundColor: "white",
               }}
            >
               <Box sx={{ opacity: checked ? 1 : 0.5, pointerEvents: checked ? "auto" : "none" }}>
                  <CompanyCoordination beneficiary />
               </Box>
            </Box>
         )}
      </>
   );
};
