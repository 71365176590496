import { HeadCell, TableComponent } from "../../../../../TableComponent";
import EditIcon from "@mui/icons-material/Edit";

const EmployerRegistryUsersTable = () => {
   const headers: HeadCell[] = [
      { headerName: "Nombres(s)", type: "string", field: "name" },
      { headerName: "Correo electrónico", type: "string", field: "email", align: "center" },
      { headerName: "Teléfono", type: "string", field: "phoneNumber", align: "center" },
      { headerName: "Roles", type: "list", field: "roles", align: "center" },
      {
         headerName: "Modificar",
         type: "button",
         align: "center",
         field: "modify",
         icon: <EditIcon fontSize="small" />,
         onClick: (e, i) => {
            console.log({ e });
         },
      },
   ];

   return (
      <TableComponent
         disableBorders
         defaultColumnToOrder="number"
         defaultOrder="desc"
         defaultRowsPerPage={5}
         emptyDataText="No data"
         loader={false}
         onClick={() => {}}
         rowsPerPageOptions={[5, 10, 15]}
         headers={headers}
         rows={[
            {
               name: "Juan",
               email: "juan@example.com",
               phoneNumber: "1234567890",
               roles: ["Administrador", "Gerente"],
               modify: () => {
                  console.log("Modifying user");
               },
            },
         ]}
      />
   );
};

export default EmployerRegistryUsersTable;
