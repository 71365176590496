import { Box, Grid, Typography } from "@mui/material";

const RepseModuleInfo = () => {
   return (
      <Box bgcolor={"#EFEFEF"} borderRadius={2} my={2} p={2}>
         <Typography fontWeight={600} pb={2}>
            REPSE
         </Typography>
         <Grid container spacing={3}>
            <Grid item xs={4}>
               <Typography fontWeight={600}>Campo de info:</Typography>
               <Typography>Descripciónasd</Typography>
            </Grid>
            <Grid item xs={4}>
               <Typography fontWeight={600}>Sector económico:</Typography>
               <Typography>Servicios</Typography>
            </Grid>
            <Grid item xs={4}>
               <Typography fontWeight={600}>Fecha de registro:</Typography>
               <Typography>13-06-23</Typography>
            </Grid>
            <Grid item xs={4}>
               <Typography fontWeight={600}>Formulario inicial:</Typography>
               <Typography>Descripción</Typography>
            </Grid>
            <Grid item xs={4}>
               <Typography fontWeight={600}>Estado:</Typography>
               <Typography>Sonora</Typography>
            </Grid>
            <Grid item xs={4}>
               <Typography fontWeight={600}>Sucursales:</Typography>
               <Typography>03</Typography>
            </Grid>
         </Grid>
      </Box>
   );
};

export default RepseModuleInfo;
